import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import PendingSessionTable from './components/PendingSessionTable';
import DeletePatient from "./DeletePatient.js";
import { ReactSVG } from "react-svg";
import PreStartTest from "./PreStartTest.js"; 
import { useTranslation } from 'react-i18next';
import { backBtn, deleteUserIcon, editIcon, actionIcon, checkMarkIcon, downloadIcon } from '../../../../icons';
import { convertToEnDateFormat, convertToFiDateFormat, isEng } from "../../../../components/util/utils.js";
import LoadingRing from '../../../../components/LoadingRing/index.js';
import { useIsAnnonymousVersion } from '../../../../components/util/isAnnonymousVersion.js';
import CopyToClipboard from '../../../../components/util/CopyToClipboard.js';

const ViewPatient = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const isAnnonymous = useIsAnnonymousVersion();
    const [popup, setPopup] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [patientSessions, setPatientSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSessionId, setSelectedSessionId] = useState('');
    const [successNotification, setSuccessNotification] = useState(null);

    const [patientName, setPatientName] = useState("");
    const [patientLastName, setPatientLastName] = useState("");
    const [patientShortID, setPatientShortID] = useState("");
    const [patientDOB, setPatientDOB] = useState("");
    const [patientLang, setPatientLang] = useState("");
    const [patientGender, setPatientGender] = useState("");
    const [patientHandedness, setPatientHandedness] = useState("");
    const [patientComment, setPatientComment] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const tooltipRef = useRef(null);
    const commentRef = useRef(null);

    const handleTooltipToggle = () => {
        setShowTooltip(!showTooltip);
    };

    const handleClickOutside = (event) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        if (showTooltip) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showTooltip]);

    const [isExpanded, setIsExpanded] = useState(false);

    const handleViewMore = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (commentRef.current) {
            const lineHeight = parseFloat(getComputedStyle(commentRef.current).lineHeight);
            const maxHeight = lineHeight * 3; // 3 lines max
            setIsOverflowing(commentRef.current.scrollHeight > maxHeight);
        }
    }, [patientComment]);

    const patientID = id;

    const getPatientDetails = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(`/Patients/${id}`);   
            const currentPatient = response.data;

            setPatientName(currentPatient.firstName);
            setPatientLastName(currentPatient.lastName);
            setPatientDOB(currentPatient.birthDate);
            setPatientLang(currentPatient.language);
            setPatientGender(currentPatient.gender);
            setPatientHandedness(currentPatient.handedness);
            setPatientComment(currentPatient.otherComments);
            setPatientShortID(currentPatient.shortId);

            setIsLoading(false);

        } catch (err) {
            setErrMsg('Failed to fetch patient details');
            setIsLoading(false);
        }
    }, [axiosPrivate, patientID]);

    const getPatientsSessions = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(`/Patients/${patientID}/ExerciseSessions`);
            const responseData = response.data.sort((a, b) => new Date(b.testStartTime) - new Date(a.testStartTime));

            const sessionsWithUploadCodes = await Promise.all(responseData.map(async (session) => {
                try {
                    const uploadCodeResponse = await axiosPrivate.get(`/exercisesessions/${session.id}/UploadCode`);
                    session.uploadCode = uploadCodeResponse.data;
                } catch (err) {
                    console.error(`Failed to fetch upload code for session ${session.id}`, err);
                }
                return session;
            }));

            setPatientSessions(sessionsWithUploadCodes);
           
        } catch (err) {
            setErrMsg('Failed to fetch patient sessions');
        }
    }, [axiosPrivate, patientID]);
    
    useEffect(() => {
        setPopup('');
        setIsLoading(true);
        getPatientDetails();
        getPatientsSessions();

        const notification = localStorage.getItem('successNotification');
        if (notification) {
            setSuccessNotification(notification);

            const timer = setTimeout(() => {
                setSuccessNotification(null);
                localStorage.removeItem('successNotification');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [getPatientDetails, getPatientsSessions]);

    const findLanguage = (val) => {
        const languageObject = {
            'fi-FI': 'Finnish',
            'sv-SE': 'Swedish',
            'de-DE': 'German',
            'fr-FR': 'French',
            'en-GB': 'English',
            'en-US': 'English'
        };
        return languageObject[val] || "Finnish";
    };

    if (isLoading) {
        return <LoadingRing/>;
    }

    const openPreStartTest = (id) => {
        setSelectedSessionId(id);
        setPopup('prestart-test');
    };

    console.log("viewptient", isAnnonymous);

    return (
        <div className="peili-main-wrapper">
            <div className="peili-views-wrapper">
                <h1>{t("view_patient_heading")}</h1>
                <div className="peili-border"></div>
                <div className="mb-1">
                    <div className="row align-items-center">
                        <div className="col-6">
                            {isAnnonymous ? (
                                <h4 className="text-blue">{t("patient_id")}: {patientShortID}<CopyToClipboard data={patientShortID}  /></h4> 
                                    ) : (
                               <h4 className="text-blue">{patientName} {patientLastName}</h4> 
                            )}
                                        
                        </div>
                        <div className="col-md-6 text-end">
                            <Link className="button btn-yellow" to={'/'}>
                                <ReactSVG src={backBtn} />
                                <span>{t("back_btn")}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <section className="patient-session-table">
                    {popup === 'prestart-test' && (
                        <PreStartTest
                            sessionId={selectedSessionId}
                            setPopupState={setPopup}
                        />
                    )}

                    {popup === 'delete-patient' && (
                        <DeletePatient
                            patientId={patientID}
                            setPopupState={setPopup}
                        />
                    )}
                       
                    <div className="row peili-patient-top-holder">
                        <div className="col-md-6">
                            <div className="contact-details">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="title mr-2">{t("patient_details")}</div>
                                    <div className="icon-btn" ref={tooltipRef}>
                                        <div onClick={handleTooltipToggle}>
                                            <ReactSVG src={actionIcon} />
                                        </div>

                                        {showTooltip && (
                                            <div className="tooltip-holder shadow">
                                                <Link className="button btn-white" to={`/edit-patient?id=${patientID}`}>
                                                    <ReactSVG src={editIcon} />
                                                    <span>{t("edit_patient")}</span>
                                                </Link>

                                                <button className='button cancel-btn' onClick={() => setPopup('delete-patient')}>
                                                    <ReactSVG src={deleteUserIcon} />
                                                    {t("delete_patient")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    {isAnnonymous ? (
                                        <>
                                        </>
                                        ) : (
                                       <>
                                            <div className="col-md-4 label">{t("firstname")}:</div>
                                            <div className="col-md-8">{patientName}</div>

                                            <div className="col-md-4 label">{t("lastname")}:</div>
                                            <div className="col-md-8">{patientLastName}</div>
                                       </> 
                                    )}
                                    

                                    <div className="col-md-4 label">{t("date_of_birth")}:</div>
                                    <div className="col-md-8">
                                        {isEng() ? convertToEnDateFormat(patientDOB) : convertToFiDateFormat(patientDOB)}
                                    </div>

                                    <div className="col-md-4 label">{t("test_language")}:</div>
                                    <div className="col-md-8">{t(findLanguage(patientLang))}</div>

                                    <div className="col-md-4 label">{t("gender")}:</div>
                                    <div className="col-md-8">{["other", "muu"].includes(t(patientGender)?.toLowerCase()) ? "–" : t(patientGender)}</div>
                                    
                                    <div className="col-md-4 label">{t("handedness")}:</div>
                                    <div className="col-md-8">{patientHandedness === "left" ? t("Left") : patientHandedness === "right" ? t("Right") :  "–"}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-3 contact-details">
                                {patientComment && (
                                    <>
                                        <div className="mb-2 title">{t("other_info")}</div>
                                        <div 
                                            className={`other-info-view ${isExpanded ? '' : 'truncated'}`}
                                            ref={commentRef}
                                            style={{ maxHeight: isExpanded ? 'none' : '4.5rem', overflow: 'hidden' }}
                                        >
                                            {patientComment}
                                        </div>
                                        {isOverflowing && !isExpanded && (
                                            <button className="word-button p-0 info-action" onClick={handleViewMore}>
                                                {t("show_more")}
                                            </button>
                                        )}
                                        {isExpanded && (
                                            <button className="word-button p-0 info-action" onClick={handleViewMore}>
                                                {t("show_less")}
                                            </button>
                                        )}
                                    </>
                                )}

                            </div>
                        </div>
                    </div>

                    <PendingSessionTable
                        patientSessions={patientSessions}
                        openPreStartTest={openPreStartTest}
                        patientId={patientID}
                    />

           
                       <div  className={`success-notification ${successNotification ? 'visible' : 'hidden'}`}>
                            <div className="d-flex notification-box">
                                <ReactSVG src={checkMarkIcon} />
                                <div className="align-self-center">
                                    {isAnnonymous ? (
                                        <>
                                           {t("add_patient_success")}
                                        </>
                                        ) : (
                                       <>
                                            {t("notify_guardian_success")}
                                       </> 
                                    )}
                                </div>
                            </div>
                        </div>


                </section>
            </div>
        </div>
    );
};

export default ViewPatient;
