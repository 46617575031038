import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";
import { useTranslation } from 'react-i18next';
import { sortUp, sortDown } from '../../icons';
import { convertToEnDateFormat, convertToFiDateFormat, isEng } from "../util/utils.js";
import { useIsAnnonymousVersion } from "../util/isAnnonymousVersion.js";
import GetPatientStatus from "../../pages/home/professionalUser/components/GetPatientStatus.js";

const PatientTable = ({ data, openViewPatient }) => { 
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); 
    const [sortDirection, setSortDirection] = useState('asc');
    const [statuses, setStatuses] = useState({});
    const [loading, setLoading] = useState(true); // Added state for loading
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isAnnonymous = useIsAnnonymousVersion;

    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                const statusPromises = data.map((patient) =>
                    GetPatientStatus(patient.id).then((status) => [patient.id, status])
                );
                const resolvedStatuses = await Promise.all(statusPromises);
                const newStatuses = Object.fromEntries(resolvedStatuses);
                setStatuses(newStatuses);
            } catch (error) {
                console.error("Error fetching patient statuses:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchStatuses();
    }, [data]);

    const handleSort = () => {
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        if (newSortDirection === 'asc') {
            data.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate));
        } else {
            data.sort((a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate));
        }
    };

    const indexOfLastItem = page * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const findLanguage = (val) => {
        const languageObject = {
            'fi-FI': 'Finnish',
            'sv-SE': 'Swedish',
            'de-DE': 'German',
            'fr-FR': 'French',
            'en-GB': 'English',
            'en-US': 'English'
        };
        return languageObject[val] || "Finnish";
    };

    const viewPatient = (e, id) => {
        e.preventDefault();
        navigate(`/view-patient?id=${id}`);
    };

    const renderStatus = (status) => {
        if (status === undefined) {
            return <div className="testStatus loading">{t("loading")}</div>; 
        }
        switch (status) {
            case "done":
                return <div className="testStatus text-green">{t("done")}</div>;
            case "pending":
                return <div className="testStatus text-orange">{t("pending")}</div>;
            default:
                return <div className="testStatus text-red">{t("none")}</div>;
        }
    };

    const currentPage = () => {
        const pageNo = localStorage.getItem('patientPageNo');
        setPage(parseInt(pageNo));
    }


    useEffect(() => {
        currentPage();
    }, [rowsPerPage]);

    return (
      <>
        <table className="peili-table peili-users-table">
            <thead>
                <tr>
                    {isAnnonymous() ? (
                        <>
                            <th className='table-header'>{t("patient_id")} </th>
                        </>
                        ) : (
                        <>
                            <th className='table-header'>{t("firstname")} </th>
                            <th className='table-header'>{t("lastname")} </th>
                        </> 
                    )}
                    <th className='table-header'>{t("status")} </th>
                    <th className='table-header'>{t("date_of_birth")} </th>
                    <th className='table-header'>{t("gender")} </th>
                    <th className='table-header'>{t("language")} </th>
                    <th className='table-header' onClick={handleSort}>
                        {t("date_modified")}
                        <img className="sort-icon" src={sortDirection === 'asc' ? sortUp : sortDown} alt="Sort Icon" /> {/* Display sort icon */}
                    </th>
                </tr>  
            </thead> 
            <tbody>
            {currentItems.map((el, i) =>  
                <tr key={"user-" + i} onClick={(e) => {viewPatient(e, el.id)}}>
                    {isAnnonymous() ? (
                        <>
                            <td>{el?.shortId}</td>
                        </>
                        ) : (
                        <>
                            <td>{el?.firstName}</td>
                            <td>{el?.lastName}</td> 
                        </> 
                    )}
                    <td>{renderStatus(statuses[el?.id])}</td> {/* Display loading or status */}
                    <td>{isEng() ? convertToEnDateFormat(el?.birthDate) : convertToFiDateFormat(el?.birthDate)}</td>
                    <td>{["other", "muu"].includes(t(el?.gender)?.toLowerCase()) ? "–" : t(el?.gender)}</td>
                    <td>{t(findLanguage(el?.language))}</td>
                    <td>{isEng() ? convertToEnDateFormat(el?.modifiedDate) : convertToFiDateFormat(el?.modifiedDate)}</td>
                </tr>
            )}
            </tbody>
        </table>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} patientTable={true}/>
     </>
    );
};

export default PatientTable;
