import { useEffect, useRef, useState } from 'react';
import { ReactSVG } from "react-svg";
import { userIcon } from '../../icons';
import useAuth from '../../hooks/useAuth';
import Logout from './Logout';
import { useTranslation } from 'react-i18next';

function UserControl(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const { auth } = useAuth();
  const [popup, setPopup] = useState('');
  const { t } = useTranslation();

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const cancel = () => {
    setPopup('');
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEditProfile = () => {

    setShowTooltip(false);
  };

  const handleLogout = () => {

    setShowTooltip(false);
  };

  return (
    <>
    <div className="main-nav user-control">
        <div className="peili-border no-margin"></div>
        <div className="nav-selections">
          <div className="nav-item" ref={tooltipRef}>
          <a onClick={toggleTooltip}>
            <ReactSVG src={userIcon} />
              <div className="user-holder">
                <span className="label">{t("nav_username")}</span>
                <span className="user">{auth.user}</span>
              </div>
          </a>

          {showTooltip && (
            <div className="tooltip-content">
              <ul>
                <li className='d-none'>
                  <button className="button" onClick={handleEditProfile}>{t("nav_edit_profile")}</button>
                </li>
                <li>
                  <button className="word-button theme-orange strong" onClick={props.confirmLogout}>{t("nav_logout")}</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}

export default UserControl;
