import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { instructionsIcon, homeIcon, supportIcon, addPatientIcon, globeIcon } from '../icons';
import { ReactSVG } from "react-svg";
import NavToolTip from './NavToolTip'; // Import the NavToolTip component
import Support from './Support';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';

function Menu() {  
  const [popup, setPopup] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default to 'en'
  const { auth } = useAuth();
  const { t } = useTranslation();

  const cancel = () => {
    setPopup('');
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng') || 'en';
    // Normalize the language (e.g., en-US becomes en)
    const normalizedLanguage = savedLanguage.startsWith('en') ? 'en' : savedLanguage;
    setSelectedLanguage(normalizedLanguage); // Set the normalized language
  }, []);

  function handleLanguageChange(lang) {
    i18next.changeLanguage(lang);
    setSelectedLanguage(lang);
  }

  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Finnish', value: 'fi' },
  ];

  return (  
    <>  
      <div className="main-nav">
        <div className="nav-selections">
          <div className="nav-item">
            {/* Updated NavLink for Home */}
            <NavLink 
              to={'/'} 
              onClick={cancel} 
              className={({ isActive }) => 
                isActive || window.location.pathname.startsWith('/view-patient') || window.location.pathname.startsWith('/edit-patient')
                  ? "active" 
                  : ""
              }
            >
              <ReactSVG src={homeIcon} /><span>{t('nav_home')}</span>
            </NavLink>
          </div>
          { auth.userType === 'MedicalUser' ?
            <div className="nav-item">
            <NavLink to={'/add-patient'} onClick={cancel}>
              <ReactSVG src={addPatientIcon} /><span>{t('nav_add_patient')}</span>
            </NavLink>
           </div>
            : null
          }

          <div className="nav-item">
            <NavLink to={'/support'} onClick={cancel}>
              <ReactSVG src={instructionsIcon} /><span>{t('nav_guide')}</span>
            </NavLink>
          </div>

          <Support
            label={t("nav_support")}
            icon={supportIcon}
          />

          {/*<div className="nav-item">
            <NavLink to={'/about'} onClick={cancel}>
              <ReactSVG src={instructionsIcon} /><span>About</span>
            </NavLink>
          </div> */}

          <NavToolTip
            label={t("nav_language")}
            icon={globeIcon}
            options={languageOptions}
            selectedOption={selectedLanguage}
            onSelect={handleLanguageChange}
          />
        </div>
      </div>
    </>
  );
}

export default Menu;
