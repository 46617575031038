import React from 'react';
import { useTranslation } from 'react-i18next';
import { smileyHappierIcon, smileyHappyIcon, smileyMehIcon, smileySadIcon, smileySadderIcon } from '../../../../../icons/index.js';
import { ReactSVG } from "react-svg";


const QuestionaireResult = (props) => {
   const questionaireAnswers = JSON.parse(props.result.data);
   const { t } = useTranslation();
   
   const iconResult = (number) => {
        if(number === "-1"){
            return t("noAnswer");
        }else if(number === "0"){
           return <ReactSVG src={smileySadderIcon} />;
        }else if(number === "1"){
            return <ReactSVG src={smileySadIcon} />;
        }else if(number === "2"){
            return <ReactSVG src={smileyMehIcon} />;
        }else if(number === "3"){
            return <ReactSVG src={smileyHappyIcon} />;
        }else{
            return <ReactSVG src={smileyHappierIcon} />; 
        }
   };

   return (
       <div className="questionaire-div">
           <div className="row">
               <div className="col-4">
                   <h4>
                       {t("end-of-session-responses")}
                   </h4>
               </div>
               <div className="col-8">
                   <div className="question-container">
                        <div className="questionaire">
                            <div className="question">
                                {t("endQuestionnaireUsedMouse")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireUsedMouse ? (
                                    t(questionaireAnswers.endQuestionnaireUsedMouse)
                                ) : (
                                    t("noAnswer")
                                )}
                            </div>
                        </div>
                       
                        <div className="questionaire">
                            <div className="question">
                                    {t("endQuestionnaireMouseProblems")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireMouseProblems ? (
                                   t(questionaireAnswers.endQuestionnaireMouseProblems)
                                ) : (
                                    t("noAnswer")
                                )}
                            </div>
                        </div>
                        
                        <div className="questionaire">
                            <div className="question">
                                {t("endQuestionnaireTechnicalProblems")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireMouseProblems ? (
                                    t(questionaireAnswers.endQuestionnaireTechnicalProblems)
                                ) : (
                                    t("noAnswer")
                                )}
                                
                            </div>
                        </div>
                        
                        <div className="questionaire">
                            <div className="question">
                                {t("endQuestionnaireDisturbances")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireDisturbances ? (
                                   t(questionaireAnswers.endQuestionnaireDisturbances)
                                ) : (
                                    t("noAnswer")
                                )}
                            </div>
                        </div>

                        {questionaireAnswers.endQuestionnaireDisturbances === "endQuestionnaireDisturbancesAnswerYes" && (
                            <div className="questionaire">
                            <div className="question">
                                {t("endQuestionnaireDisturbancesYes")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireDisturbancesYes ? (
                                   t(questionaireAnswers.endQuestionnaireDisturbancesYes)
                                ) : (
                                    t("noAnswer")
                                )}
                            </div>
                        </div>
                        )}

                        <div className="questionaire">
                            <div className="question">
                                {t("endQuestionnaireSupervisorHelp")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireSupervisorHelp ? (
                                   t(questionaireAnswers.endQuestionnaireSupervisorHelp)
                                ) : (
                                    t("noAnswer")
                                )}
                            </div>
                        </div>

                        {questionaireAnswers.endQuestionnaireSupervisorHelp === "endQuestionnaireSupervisorHelpAnswerYes" && (
                            <div className="questionaire">
                                <div className="question">
                                    {t("endQuestionnaireSupervisorHelpYes")}
                                </div>
                                <div className="answer strong">
                                    {questionaireAnswers.endQuestionnaireSupervisorHelpYes 
                                        ? t(questionaireAnswers.endQuestionnaireSupervisorHelpYes) 
                                        : t("noAnswer")}
                                </div>
                            </div>
                        )}
                       
                        <div className="questionaire">
                            <div className="question">
                                {t("endQuestionnaireComments")}
                            </div>
                            <div className="answer strong">
                                {questionaireAnswers.endQuestionnaireComments ? (
                                   t(questionaireAnswers.endQuestionnaireComments)
                                ) : (
                                    t("noAnswer")
                                )}
                            </div>
                        </div>
   
                        <div className="questionaire">
                            <div className="question mb-2">
                                {t("endQuestionnaireSmiley")}
                            </div>
                            <div className="answer strong align-center">
                                {iconResult(questionaireAnswers.endQuestionnaireSmiley)}
                            </div>
                        </div>
                   </div>
               </div>
           </div>
       </div>
   );
};

export default QuestionaireResult;
