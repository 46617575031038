import React, { useState, useEffect } from "react";
import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";
import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'react-international-phone';
import { sortUp, sortDown } from '../../icons'; // Import the sort icons
import { convertToEnDateFormat } from "../util/utils.js";
import PreStartTest from "../../pages/home/parentUser/PreStartTest.js";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { ReactSVG } from "react-svg";
import { playIcon, playIconSecondary, userIcon2, downloadIcon } from '../../icons';
import useAuth from '../../hooks/useAuth';

const TestTable = ({ data, openViewPatient, loaded }) => { 

    const { auth, setAuth } = useAuth();

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); 
    const [sortDirection, setSortDirection] = useState('asc'); // Track the current sort direction
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { t } = useTranslation();

    const [ sessionData, setSessionData ] = useState(false);  

    const axiosPrivate = useAxiosPrivate();

    const [popup, setPopup] = useState('');
    const [credentials, setCredentials] = useState('');


    const openPreStartTest = (el) => {
        //e.stopPropagation();
        let sessionId = el.sessionData[0]['id'];
        setSessionData(sessionId); 
        setPopup('prestart-test');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // 'en-GB' locale formats the date as DD/MM/YYYY
    };
    
    const handleResize = () => {
        if (window.innerHeight < 800) {
            setRowsPerPage(5)
        } else if (window.innerHeight < 850) {
            setRowsPerPage(6)
        } else if (window.innerHeight < 1000) {
            setRowsPerPage(7)
        } else if (window.innerHeight < 1200) {
            setRowsPerPage(10)
        } else if (window.innerHeight < 1500) {
            setRowsPerPage(15)
        }
    }

    useEffect(() => {
        handleResize();
    }, [rowsPerPage]);

    const currentItems = data; // data.slice(indexOfFirstItem, indexOfLastItem);

    const getPatientSessionData = async () => {

        try {

            for (let i = 0; i < currentItems.length; i++) {
                const item = currentItems[i];
                // Perform operations with the item
                //console.log(item); // Example operation

                const response = await axiosPrivate.get('/patients/' + item.id + '/exercisesessions',  {
                    //signal: controller.signal
                });
                
                const data = response.data;
                if(data.length > 0) {
                    currentItems[i].sessionData = data;
                    
                }
                
            }
            //console.log(currentItems);
           

        } catch (err) {
            if (!err?.response) {
            //setErrMsg('No server response');
            } else if (err.response?.status === 400) {
            //setErrMsg('Invalid verification code');
            } else if (err.response?.status === 401) {
            //setErrMsg('401 Unauthorized');
            } else {
            //setErrMsg('Fetch failed');
            }
            //errRef.current.focus();
        }
    };

    const getParentReport = async (id) => {
        try {

            const response = await axiosPrivate.get('/exercisesessions/' + id + '/parentreport/pdf',  {
                //signal: controller.signal
                responseType: 'blob'
            });
                
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create an anchor element and set its href attribute to the blob URL
            const link = document.createElement('a');
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'parent_report.pdf'); // Change the filename as needed

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the anchor to trigger the download
            link.click();

            // Clean up by removing the anchor and revoking the blob URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            if (!err?.response) {
            //setErrMsg('No server response');
            } else if (err.response?.status === 400) {
            //setErrMsg('Invalid verification code');
            } else if (err.response?.status === 401) {
            //setErrMsg('401 Unauthorized');
            } else {
            //setErrMsg('Fetch failed');
            }
            //errRef.current.focus();
        }
    }

    const downloadParentReport = (e, id) => {
        e.stopPropagation();
        getParentReport(id);
        //window.open('/api/parentreport/' + id, '_blank');
    }

    //getPatientSessionData();


    return (
      <>

        <div className={"peili-table peili-tests-table " + loaded}>

        {popup === 'prestart-test' && (
                <PreStartTest
                    patientCredentials={credentials}
                    sessionData={sessionData}
                    //errorMessage={errMsg}
                    //setInitState={setInitState}
                    //goBack={goBack}
                    setPopupState={setPopup}
                    handleClick={true}
                />
            )}

            <div className="pending-tests">
                
                {
                
                !data || data.every(el => el.sessionData && el.sessionData[0]?.finalized === true) ? t('table_no_tests') :
                data.filter(el => el.sessionData && el?.sessionData[0]?.finalized === false)
                    .map((el, i) => (
                    <div className="item" key={"user-" + i} onClick={(e) => { e.stopPropagation(); openPreStartTest(el); }}>
                        <span className="icon person"><ReactSVG src={userIcon2} /></span>
                        <p>{el?.nickname} -</p>
                        <p className="date-completed">{formatDate(el.sessionData[0]?.['createdDate'])}</p>
                        <div className="icon-wrapper">
                            <p className="play-here-text"> {t("table_start_link")}</p>
                            <span className="icon play" ><ReactSVG src={playIconSecondary} /></span>
                        </div>

                    </div>
                )) 
                }

            </div>

            <div className="completed-tests">
                <h3>{t('test_table_heading')}</h3>
                {
                !data || !data.some(el => el.sessionData && el.sessionData[0]?.finalized === true) ? t('table_no_results') :
                data
                    .filter(el => el?.sessionData && el.sessionData[0]?.finalized === true)
                    .map((el, i) => (
                        //console.log(el.sessionData),
                        <div className={"item" + (el.sessionData[0]?.parentReportUploaded === false ? ' waiting' : '')} key={"user-" + i} onClick={(e) => downloadParentReport(e, el.sessionData[0]?.['id'])}>

                           {
                                el.sessionData[0]?.parentReportUploaded === false ?
                                
                                    <><p>{t('table_waiting_results')}</p></>    
                                :
                                    <><span className="icon person"><ReactSVG src={userIcon2} /></span>
                                    <p>{el?.nickname} -</p>
                                    <p className="date-completed">{formatDate(el.sessionData[0]?.['testEndTime'])}</p>
                                    <div className="icon-wrapper">
                                        <p className="download-here-text"> {t("table_download")}</p> 
                                        <span className="icon download"><ReactSVG src={downloadIcon} /></span>
                                    </div>
                                </>      
                           }
                        </div>
                    ))
                    }
            </div>
        </div>
        
     </>
    );
};

export default TestTable;
