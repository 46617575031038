import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';


export default function InfoGraph() {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";


  const handleSupportClick = (event) => {
    event.preventDefault(); // Prevent the default navigation behavior
    navigate('/support', { replace: true });
  };

  return (
    <div className="info-graph-container">
        <h3>{t("parent_info_heading_1")}</h3>
        <div className='text-container'>
          <p><b>{t("parent_info_subheading_1")}:</b></p>
            <p>{t("parent_info_string_1")}                
            <a href="" onClick={handleSupportClick}> {t("parent_info_guide_link")}</a></p>
        
            <p><br></br><b>{t("parent_info_subheading_2")}:</b></p>

            <p>{t("parent_info_string_2")}</p>

            <p><br></br><b>{t("parent_info_subheading_3")}:</b></p>

            <p>{t("parent_info_string_3")}</p>


            <h3><br></br>{t("parent_info_heading_2")}</h3>


            <p><br></br><b>{t("parent_info_subheading_4")}:</b></p>

            <p>{t("parent_info_string_4")}</p>

            <p><b>{t("parent_info_subheading_5")}:</b></p>

            <p>{t("parent_info_string_5")}</p>

            <h3><br></br>{t("parent_info_heading_3")}</h3>

            <p><b>{t("parent_info_subheading_6")}:</b></p>

            <p>{t("parent_info_string_6")}</p>


            <p><br></br><b>{t("parent_info_subheading_7")}</b></p>
            <p>{t("parent_info_string_7")} <a href="mailto:support@efsimtest.com">support@efsimtest.com</a></p>
        </div>
    </div>
  )
}
