import { React, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ResultInterpretation from "./ResultInterpretation.js";
import { useLocation, Link } from 'react-router-dom';
import { ReactSVG } from "react-svg";
import { backBtn, downloadIcon } from '../../../../icons/index.js';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import LoadingRing from '../../../../components/LoadingRing/index.js';
import DownloadParentReport from '../components/DownloadParentReport';
import { convertToFiDateTimeFormat, convertToEnDateTimeFormat, isEng } from "../../../../components/util/utils.js";
import { useIsAnnonymousVersion } from '../../../../components/util/isAnnonymousVersion.js';
import SessionResultTable from './components/sessionResultTable';
import CopyToClipboard from '../../../../components/util/CopyToClipboard.js';
import QuestionaireResult from './components/questionaireResult.js';

const SessionDetails = () => {
    const { t } = useTranslation();
    const [popup, setPopup] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const isAnnonymous = useIsAnnonymousVersion;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get('id');
    const sessionId = searchParams.get('sessionId');
    const [errMsg, setErrMsg] = useState('');
    const [exerciseSessionData, setExerciseSessionData] = useState(null);
    const [loading, setLoading] = useState(true);


    const handleDownload = useCallback((exerciseSessionId, patientID, patientName) => {
        DownloadParentReport(axiosPrivate, exerciseSessionId,patientID,patientName);
    }, [axiosPrivate]);

    const loadSessionDetails = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axiosPrivate.get(`/exercisesessions/${sessionId}`);
            setExerciseSessionData(response.data); 
            //console.log("test", response.data)
        } catch (err) {
            setErrMsg('Failed to fetch session details');
        } finally {
            setLoading(false); 
        }
    }, [axiosPrivate, sessionId]);

    useEffect(() => {
        setPopup('');
        loadSessionDetails(); 
    }, [loadSessionDetails]);

    if (loading) {
        return <LoadingRing />;
    }

    if (errMsg) {
        return <div>{errMsg}</div>; 
    }

    const openResultInterpretation = () => {
        setPopup('result-interpretation');
    };

    const patientDetails = exerciseSessionData.patient;
    const questionaireAnswers = exerciseSessionData.additionalSessionData;

    return (
        <div className="peili-main-wrapper">
            <div className="peili-views-wrapper">
                <h1>{t("test_results_heading")}</h1>
                <div className="peili-border"></div>
              
                <div className="mb-1">
                    <div className="row align-items-center">
                        <div className="col-6">
                            {isAnnonymous() ? (
                                <>
                                    <h4 className="text-blue">{t("patient_id")}: {exerciseSessionData.patientShortId}<CopyToClipboard data={exerciseSessionData.patientShortId}  /> </h4>
                                </>
                                ) : (
                                <>
                                    <h4 className="text-blue">{patientDetails.firstName} {patientDetails.lastName}</h4>
                                </> 
                            )}
                        </div>
                        <div className="col-md-6 text-end">
                            <Link className="button btn-yellow"  to={`/view-patient?id=${patientId}`}>
                                <ReactSVG src={backBtn} />
                                <span>{t("back_btn")}</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <section className='session-details'>

                    {
                        (popup === 'result-interpretation') ?
                            <ResultInterpretation setPopupState={setPopup}></ResultInterpretation>
                            :
                            <></>
                    }
                    <div className="row">
                        <div className="col-md-6">
                            <div className='contact-details'>
                                <div className='row '>
                                    <div className='col-md-4 label'>{t("test_started")}:</div>
                                    <div className='col-md-8'>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(exerciseSessionData?.testStartTime + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return t('Invalid date');
                                            }
                                        })()}
                                    </div>
                                    <div className='col-md-4 label'>{t("test_completed")}:</div>
                                    <div className='col-md-8'>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(exerciseSessionData?.testEndTime + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return t('Invalid date');
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button 
                                className={"button btn-white-green"}  
                                onClick={() => handleDownload(sessionId, exerciseSessionData.patientShortId, patientDetails.firstName)}>
                                <ReactSVG src={downloadIcon} />
                                {t("parent_report")}
                            </button>
                        </div>
                    </div>

                    <div className="mt-4">
                        <SessionResultTable data={exerciseSessionData}></SessionResultTable>
                    </div>

                    <div className="mt-4">
                        <QuestionaireResult result={questionaireAnswers} />
                    </div>

                    <div className='mt-5 d-flex justify-content-center'>
                        <button onClick={(e) => { openResultInterpretation() }} className='button btn-purple '>{t("view_results_interpretation")}</button>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SessionDetails;