import { useTranslation } from 'react-i18next';
import { ReactSVG } from "react-svg";
import { checkMarkIcon} from '../../../icons';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import { useState, useEffect, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useNavigate, useLocation } from 'react-router-dom';

const PreStartTest = (props) => {
    const { t } = useTranslation();
    const cancel = () => {
        props.setPopupState('');
    }
    const { auth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const handleSupportClick = (event) => {
        event.preventDefault(); // Prevent the default navigation behavior
        navigate('/support', { replace: true });
      };

    let returnUrl = '';
    const currentURL = window.location.href;
    if (currentURL.includes('https://arvo-dev.azurewebsites.net/')) {
        returnUrl = 'https://arvo-dev.azurewebsites.net/';
    } else if (currentURL.includes('https://efsim-staging.azurewebsites.net/')) {
        returnUrl = 'https://efsim-staging.azurewebsites.net/';
    } else if (currentURL.includes('https://efsim.azurewebsites.net/')) {
        returnUrl = 'https://efsim.azurewebsites.net/';
    } else {
        returnUrl = window.location.href; // Fallback to environment variable
    }
    const sessionData = props.sessionData;
    const popupRef = useRef();
    useOnClickOutside(popupRef, () => props.setPopupState(''));
    const handleReady = async (id) => {
        try {
            const response = await axios.get(
                `/exercisesessions/${id}/PlayUrl`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`
                    }
                }
            );
            const playUrl = response.data;
            if (playUrl) {
                window.location.href = playUrl + "&returnUrl=" + returnUrl;
            } else {
                console.error('No URL found in the response');
            }
        } catch (error) {
            console.log('Error uploading token:', error?.response || error);
        }
    };
    const startTest = (id) => {
        //console.log(id)
        handleReady(id);
    }
    return (
        <section ref={popupRef} className="peili-popup-wrapper secondary-purple scrollable">
            <div className="content">
                <div className="text-area">
                    <h4 className='mb-4 text-center text-secondary-purple'>
                            {t("prestart_heading")}
                        </h4>
                        <div className='mb-5 text-center'>
                            {t("prestart_subheading")}:
                        </div>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                        <div className=''>
                            <ReactSVG src={checkMarkIcon} />
                        </div>
                        <div className="items-to-check">
                            <span className='strong'>{t("prestart_bullet_1")}: </span> {t("prestart_string_1")}
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                        <div className=''>
                            <ReactSVG src={checkMarkIcon} />
                        </div>
                        <div className="items-to-check">
                        <span className='strong'>{t("prestart_bullet_2")}: </span> {t("prestart_string_2")}
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-3 mb-3'>
                        <div className=''>
                            <ReactSVG src={checkMarkIcon} />
                        </div>
                        <div className="items-to-check">
                        <span className='strong'>{t("prestart_bullet_3")}: </span> {t("prestart_string_3")}
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-3 mb-5'>
                        <div className=''>
                            <ReactSVG src={checkMarkIcon} />
                        </div>
                        <div className="items-to-check">
                        <span className='strong'>{t("prestart_bullet_4")}: </span> {t("prestart_string_4")}
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-3 mb-5'>

                    
                        <div className="items-to-check">
                            <span className='strong'>{t("prestart_subheading_1")} <br></br><br></br></span>
                            {t("prestart_string_5")}
                        </div>
                        
                    </div>
                    <div className=' text-center '>
                        <div className="items-to-check">
                            <span className='strong'>{t("prestart_subheading_2")} </span><br></br><br></br>
                            {t("prestart_string_6")} <a href="" onClick={handleSupportClick}>{t("prestart_link")}</a> {t("prestart_string_7")}
                        </div>
                    </div>
                </div>
                <div className="buttons-wrapper d-flex gap-3 mt-5 justify-content-center">
                    <button className='button cancel-btn' onClick={() => cancel() }>{t("prestart_cancel")}</button>
                    <button className='button btn-purple' onClick={(id) => startTest(sessionData) } >{t("prestart_ready")}</button>
                </div>
            </div>
        </section>
    )
}
export default PreStartTest