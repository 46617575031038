import React from 'react';
import { useTranslation } from 'react-i18next';
import { dotsIcon, dotsPlusIcon, dotsMinusIcon, circleQuestion } from '../../../../../icons/index.js';
import { ReactSVG } from "react-svg";
import Tooltip from "../../../../../components/util/Tooltip.js";

const SessionResultTable = (props) => {
    const { t } = useTranslation();
    const exerciseSessionData = props.data;
    let processedNPIResults = exerciseSessionData?.processedNPIResults || [];

    let DailyLivingTotalScore1MeasureVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingTotalScore1Measure") || {};
    let DailyLivingTaskEfficacy1MeasureVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingTaskEfficacy1Measure") || {};
    let DailyLivingNavigationEfficacyMeasureVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNavigationEfficacyMeasure") || {};
    let DailyLivingActions3MeasureVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingActions3Measure") || {};
    let DailyLivingNumTimesLookedAtWatchVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNumTimesLookedAtWatch") || {};
    let DailyLivingNumCorrectTimedTasksVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNumCorrectTimedTasks") || {};
    let DailyLivingNumCorrectInterruptionTasksVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNumCorrectInterruptionTasks") || {};
    let DailyLivingControllerMotionMeasureVal = processedNPIResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingControllerMotionMeasure") || {};


    const truncateDecimal = (number) => {
        const factor = Math.pow(10, 3);
        return Math.floor(number * factor) / factor;
    };

    const resultDescription = (z) => {
        if (z === 3) {
            return <div className="desc green">{t("average")}</div>;
        } else if (z === 2) {
            return <div className="desc yellow">{t("slightly_atypical")}</div>;
        } else if (z === 1) {
            return <div className="desc red">{t("very_atypical")}</div>;
        }
    };

    const dotRange = (value, range) => {
        const intValue = Math.trunc(value);
        const decimalValue = value - Math.floor(value); // Get the decimal part
        const percentage = decimalValue * 100; // Convert to percentage (0 to 100)
        const leftPercentage = `${percentage}%`; // Convert to a percentage string
    
        if (range === 3) {
            if (value < 4) {
                return  <ReactSVG
                            style={{ left: "1000%" }}
                            src={dotsPlusIcon} 
                        />;
            }
            if (value > 3) {
                return  <ReactSVG 
                            src={dotsIcon}
                            style={{ left: leftPercentage }}
                        />;
            }
            if (intValue === 3) {
                return  <ReactSVG 
                            src={dotsIcon}
                            style={{ left: leftPercentage }}
                        />;
            }
            return "";
        }
    
        if (range === -3) {
            if (value < -4) {
                return  <ReactSVG
                            style={{ left: "0%" }}
                            src={dotsMinusIcon} 
                        />;
            }
            
            if (value < -3) {
                return  <ReactSVG
                            style={{ left: leftPercentage }}
                            src={dotsIcon} 
                        />;
            }
            if (intValue === -3) {
                return  <ReactSVG 
                            src={dotsIcon}
                            style={{ left: leftPercentage }}
                        />;
            }
            return "";
        }
    
        if (intValue === range) {
        
            return (
                <ReactSVG
                    src={dotsIcon}
                    style={{ left: leftPercentage }}
                />
            );
        }
       
        return "";
    };

    return (
        <div className="session-result-table">
            <table className="peili-table session-table version-3">
                <thead>
                    <tr>
                        <th className="table-header">{t("measure")}</th>
                        <th className="table-header">{t("rp")}</th>
                        <th className="table-header">{t("z")}</th>
                        <th className="table-header">{t("description")}</th>
                        <th className="table-header score thirdPoint">-3</th>
                        <th className="table-header score secondPoint">-2</th>
                        <th className="table-header score firstPoint">-1</th>
                        <th className="table-header score centerPoint"><div>Z</div>0</th>
                        <th className="table-header score firstPoint">1</th>
                        <th className="table-header score secondPoint">2</th>
                        <th className="table-header score thirdPoint">3</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {t("total_score")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_2")}</div>,
                                    <div key="2">-{t("result_string_3")}</div>,
                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingTotalScore1MeasureVal?.result)} / {truncateDecimal(DailyLivingTotalScore1MeasureVal?.maxValue)}</td>
                        <td>{truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingTotalScore1MeasureVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingTotalScore1MeasureVal?.zScore),3)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t("task_efficacy")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_4")}</div>,
                                    <div key="2">-{t("result_string_5")}</div>,

                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore)}</td>
                        <td>{resultDescription((DailyLivingTaskEfficacy1MeasureVal?.category))}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingTaskEfficacy1MeasureVal?.zScore),3)}</td>
                    </tr>

                    <tr>
                        <td>
                            {t("total_actions")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_6")}</div>,
                                    <div key="2">-{t("result_string_7")}</div>,
                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingActions3MeasureVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingActions3MeasureVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingActions3MeasureVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingActions3MeasureVal?.zScore),3)}</td>
                    </tr>

                    <tr className="breaker">
                        <td>
                            {t("controller_motion")}
                            <Tooltip
                                position="right"
                                content={[
                                <div key="1">-{t("result_string_8")}</div>,
                                <div key="2">-{t("result_string_9")}</div>,
                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingControllerMotionMeasureVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingControllerMotionMeasureVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingControllerMotionMeasureVal?.zScore),3)}</td>
                    </tr>

                    <tr className="pt-20">
                        <td>
                            {t("navigation_efficacy")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_10")}</div>,
                                    <div key="2">-{t("result_string_11")}</div>,

                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingNavigationEfficacyMeasureVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNavigationEfficacyMeasureVal?.zScore),3)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t("number_of_clock_checks")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_12")}</div>,
                                    <div key="2">-{t("result_string_13")}</div>,
                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingNumTimesLookedAtWatchVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNumTimesLookedAtWatchVal?.zScore),3)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t("time_based_subtasks")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_14")}</div>,
                                    <div key="2">-{t("result_string_15")}</div>,

                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingNumCorrectTimedTasksVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectTimedTasksVal?.zScore),3)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t("event_based_memory_subtasks")}
                            <Tooltip
                                position="right"
                                content={[
                                    <div key="1">-{t("result_string_16")}</div>,
                                    <div key="2">-{t("result_string_17")}</div>,

                                ]}
                            >
                                <span><ReactSVG src={circleQuestion} /> </span>
                            </Tooltip>
                        </td>
                        <td>{truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.result)}</td>
                        <td>{truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore)}</td>
                        <td>{resultDescription(DailyLivingNumCorrectInterruptionTasksVal?.category)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),-3)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),-2)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),-1)}</td>
                        <td className="score centerPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),0)}</td>
                        <td className="score firstPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),1)}</td>
                        <td className="score secondPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),2)}</td>
                        <td className="score thirdPoint">{dotRange(truncateDecimal(DailyLivingNumCorrectInterruptionTasksVal?.zScore),3)}</td>
                    </tr>
                </tbody>
            </table>   
        </div>
    );
};

export default SessionResultTable; 
