const DownloadPatientGuidelines = async (axiosPrivate, exerciseSessionId, patientShortId) => {
    try {
        const response = await axiosPrivate.get(`/exerciseSessions/${exerciseSessionId}/instructions/pdf`, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `Instruction-${patientShortId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        alert('Failed to download the report. Please try again.');
    }
};

export default DownloadPatientGuidelines;
