import React, { useState, useEffect, useRef } from 'react';
import './regular_page.css';
import SubPage from './subpage.js';
import ChildPage from './ChildPage.js';
import KnowledgeBaseIntro from '../../components/knowledgebase/knowledge_base_intro.js';
import LoadingRing from '../../components/LoadingRing/index.js';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

export default function ParentInstructions({ country, showKnowledgeBaseIntro }) {
  const [fetchedData, setFetchedData] = useState(null);
  const [blockId, setBlockId] = useState(null);           // State to hold selected blockId
  const [isActive, setIsActive] = useState(null);
  const [activeChild, setActiveChild] = useState(null);

  const { auth, setAuth } = useAuth();

  // Ref to store fetched data for each language

  const dataRef = useRef({});
  const { i18n } = useTranslation();

  

  // const pageId = "47bc9106104f4a099aa0fb02f83229c5";

  const getPageId = (lan) => {

    let id = '1042f4f4588b80048c39cbd09d2771c0';

    if(lan === 'en') {

      id = '1042f4f4588b80048c39cbd09d2771c0';

      if (auth.userType === 'MedicalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'MedicalUser') {
        id = '11c2f4f4588b807880bbf5718a4bcc43';
      } else if (auth.userType === 'PersonalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'PersonalUser') {
        id = '1042f4f4588b80048c39cbd09d2771c0';
      }

    } else if( lan === 'fi') {

      if (auth.userType === 'MedicalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'MedicalUser') {
        id = '1512f4f4588b802a9db8f4d1ec8bdc97';
      } else if (auth.userType === 'PersonalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'PersonalUser') {
        id = '1042f4f4588b80048c39cbd09d2771c0';
      }

    }
    return id;
  }

  function clickFirstDivInContainer() {
    const firstDiv = document.querySelector('.container_kb > .page_kb div:first-child > p');
  
    if (firstDiv) {
      firstDiv.click();
    }
  }

  useEffect(() => {
    let pageId = getPageId(i18n.language);
    const fetchData = async () => {
      // Check if data is already cached
      if(dataRef.current[pageId]) {
        setFetchedData(dataRef.current[pageId]);
      } else {
        try {
          const response = await fetch(`https://efsim-notion.azurewebsites.net/fetchDataFromNotion/${pageId}`);
          const data = await response.json();
          setFetchedData(data);
          // Cache the fetched data
          dataRef.current[pageId] = data;
          setTimeout(()=> {
            clickFirstDivInContainer();
          }, 10);
          
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [i18n.language]);
  const handleChildPageClick = (childId) => {
    setBlockId(childId);
    setActiveChild(childId);
  };
  const handleParagraphClick = (index) => {
    setIsActive(index);
  };
  useEffect(() => {
    setBlockId(null);   // Reset blockId to null when the language is changed to show KnowledgeBaseIntro
    setTimeout(()=> {
      clickFirstDivInContainer();
    }, 10);
  }, [i18n.language]);
  const renderBlock = (block, renderBlockIndex) => {
    switch (block.type) {
      case 'child_page':
        return (
          <ChildPage
            key={renderBlockIndex}
            block={block}
            handleChildPageClick={handleChildPageClick}
            activeChild={activeChild}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
    <div className='peili-main-wrapper'>
        <div className="peili-views-wrapper">
            <div className='peili-content-wrapper relative-position'>
                <div className="row">
                    <div className="col">
                        <div className="parent-container">
                        {fetchedData ? (
                            <>
                            <div className="container_kb">
                                <h1 className="main_h1_kb"> {fetchedData.title || "No title available"}</h1>
                                <div className="page_kb">
                                {fetchedData.processedContent.map((block, fetchedDataIndex) =>
                                    <div
                                    key={fetchedDataIndex}
                                    onClick={() => handleParagraphClick(fetchedDataIndex)}
                                    >
                                    {renderBlock(block, fetchedDataIndex)}
                                    </div>
                                )}
                                <a className="kb-support-mail" href="mailto:support@efsimtest.com">support@efsimtest.com</a>
                                </div>
                            </div>
                            {blockId ? (
                                <SubPage blockId={blockId} />
                            ) : (
                                showKnowledgeBaseIntro && <KnowledgeBaseIntro />
                            )}
                            </>
                        ) : (
                            <LoadingRing />
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

