import { axiosPrivate } from "../../../../api/axios";

const GetPatientStatus = async (patientId) => {
    try {
        const response = await axiosPrivate.get(`/patients/${patientId}/teststatus`, {
            responseType: 'text',
        });
        return response.data;
    } catch (err) {
        console.error('Failed to fetch the upload code', err);
        return "None"; 
    }
};

export default GetPatientStatus;