import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { copyIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const CopyToClipboard = ({ data }) => {
  const [tooltip, setTooltip] = useState(""); // Tracks the tooltip content
  const [isCopied, setIsCopied] = useState(false); // Tracks the copied state
  const { t } = useTranslation();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(data);
      setTooltip(t("Copied"));
      setIsCopied(true);

      setTimeout(() => {
        setTooltip("");
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
      setTooltip("Error");
    }
  };

  return (
    <div
      className="copy-to-clipboard"
      onMouseEnter={() => {
        if (!isCopied) setTooltip(t("Copy patient ID"));
      }}
      onMouseLeave={() => {
        if (!isCopied) setTooltip("");
      }}
    >
      <button onClick={handleCopy}>
        <ReactSVG src={copyIcon} />
      </button>
      {tooltip && (
        <span className={`tooltip ${tooltip ? "visible" : ""}`}>
          {tooltip}
        </span>
      )}
    </div>
  );
};

export default CopyToClipboard;
