import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

const ResultInterpretation = (props) => {

    const { t } = useTranslation();

    const cancel = () => {
        props.setPopupState('');
    }
    
    return (
        <section className={"peili-popup-wrapper secondary-purple"}>
            <h4 className='mb-4 text-secondary-purple'>
               {t("result_interpretation")}
            </h4>

            <div className='mb-3 smaller-text'>
                {t("result_string_1")}
            </div>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header> {t("total_score")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_2")}
                        </div>
                        <div>
                            - {t("result_string_3")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("task_efficacy")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_4")}
                        </div>
                        <div>
                            - {t("result_string_5")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t("total_actions")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_6")}
                        </div>
                        <div>
                            - {t("result_string_7")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("controller_motion")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_8")}
                        </div>
                        <div>
                            - {t("result_string_9")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>{t("navigation_efficacy")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_10")}
                        </div>
                        <div>
                            - {t("result_string_11")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>{t("number_of_clock_checks")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_12")}
                        </div>
                        <div>
                            - {t("result_string_13")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="6">
                    <Accordion.Header>{t("time_based_subtasks")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_14")}
                        </div>
                        <div>
                            - {t("result_string_15")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="7">
                    <Accordion.Header>{t("event_based_memory_subtasks")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("result_string_16")}
                        </div>
                        <div>
                            - {t("result_string_17")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="buttons-wrapper d-flex mt-5 justify-content-center">
                <button className='button cancel-btn' onClick={() => cancel() }>{t("close")}</button> 
            </div>
        </section>
    )
}

export default ResultInterpretation