import { useIsAnnonymousVersion } from "../../../../components/util/isAnnonymousVersion";

const DownloadParentReport = async (axiosPrivate, exerciseSessionId, patientShortId, patientName) => {
    const isAnnonymous = useIsAnnonymousVersion();
    try {
      
        const currentLanguage = localStorage.getItem("i18nextLng") || "en"; 

        const response = await axiosPrivate.get(`/ExerciseSessions/${exerciseSessionId}/ParentReport/pdf`, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        if (isAnnonymous) {
            if (currentLanguage === "fi") {
                link.setAttribute('download', patientShortId ? `EFSim_raportti_${patientShortId}.pdf` : `EFSim_raportti.pdf`);
            } else {
                link.setAttribute('download', patientShortId ? `EFSim_report_${patientShortId}.pdf` : `EFSim_report.pdf`);
            }
        } else {
            if (currentLanguage === "fi") {
                link.setAttribute('download', patientName ? `EFSim_raportti_${patientName}.pdf` : `EFSim_raportti.pdf`);
            } else {
                link.setAttribute('download', patientName ? `EFSim_report_${patientName}.pdf` : `EFSim_report.pdf`);
            }
        }        

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        alert('Failed to download the report. Please try again.');
    }
};

export default DownloadParentReport;

