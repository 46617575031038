import { useTranslation } from 'react-i18next';
import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import DownloadParentReport from '../../components/DownloadParentReport';
import DownloadPatientGuidelines from '../../components/DownloadPatientGuideline.js';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { ReactSVG } from "react-svg";
import { downloadIcon, playIcon } from '../../../../../icons/index.js';
import { convertToFiDateTimeFormat, convertToEnDateTimeFormat, isEng } from "../../../../../components/util/utils.js";
import { useIsAnnonymousVersion } from '../../../../../components/util/isAnnonymousVersion.js';

const PendingSessionTable = (props) => {
    const { t } = useTranslation();
    const patientSessionList = props.patientSessions || [];
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const isAnnonymous = useIsAnnonymousVersion;

    const pendingSessions = useMemo(() => {
        return patientSessionList.filter(el => !el?.finalized);
    }, [patientSessionList]);

    const completedSessions = useMemo(() => {
        return patientSessionList.filter(el => el?.finalized);
    }, [patientSessionList]);

    const handleDownload = useCallback((exerciseSessionId, patientShortId, patientName) => {
        DownloadParentReport(axiosPrivate, exerciseSessionId, patientShortId, patientName);
    }, [axiosPrivate]);

    const handlePatientGuideline = useCallback((exerciseSessionId, patientShortId) => {
        DownloadPatientGuidelines(axiosPrivate, exerciseSessionId, patientShortId);
    }, [axiosPrivate]);

    const viewExerciseSession = (sessionId) => {
        navigate(`/view-patient/session-details?id=${props.patientId}&sessionId=${sessionId}`);
    };

    return (
        <section className='mb-4 mt-4'>
            <h6 className='text-orange'>{t("pending_tests_heading")}</h6>
            <div className='table-holder mb-5'>
                <table className="peili-table">
                    <thead>
                        <tr>
                            <th className='table-header'>{t("test_code")}</th>
                            <th className='table-header'>{t("date_created")}</th>
                            <th className='table-header'></th>
                        </tr>  
                    </thead>
                    {pendingSessions.length > 0 ? (
                        <tbody>
                            {pendingSessions.map((el, i) => (
                                <tr key={el?.exerciseSessionId || i}>
                                    <td>{el.uploadCode}</td>
                                    <td>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(el.createdDate + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return 'Invalid date';
                                            }
                                        })()}
                                    </td>

                                    <td className='d-flex justify-content-end gap-3'>
                                        {isAnnonymous() ? (
                                            
                                            <button 
                                                className={"button btn-orange"}  
                                                onClick={() => handlePatientGuideline(el?.id,el?.patientShortId)}
                                            >
                                                <ReactSVG src={downloadIcon} />
                                                {t("download_instructions")}
                                            </button>
                                            
                                            ) : (
                                            <> </> 
                                        )}

                                        <button 
                                            className={"button btn-purple"}
                                            onClick={() => props.openPreStartTest(el?.id)}
                                        >
                                            <ReactSVG src={playIcon} />
                                            {t("start_test")}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="2">{t("no_sessions_to_display")}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>

            <h6 className='text-green mt-4'>{t("completed_tests")}</h6>
            <div className='table-holder'>
                <table className="peili-table">
                    <thead>
                        <tr>
                            <th className='table-header'>{t("date_completed")}</th>
                            <th className='table-header'></th>
                        </tr>  
                    </thead>
                    {completedSessions.length > 0 ? (
                        <tbody>
                            {completedSessions.map((el, i) => (
                                <tr key={el?.exerciseSessionId || i}>
                                    <td>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(el.testEndTime + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return t('invalid_date');
                                            }
                                        })()}
                                    </td>
                                    
                                    <td className='d-flex justify-content-end gap-3'>
                                        <button 
                                            className={"button btn-green"}  
                                            onClick={() => viewExerciseSession(el?.id)}>
                                            {t("view_results")}
                                        </button>
                                        <button 
                                            className={"button btn-white-green"}  
                                            onClick={() => handleDownload(el?.id, el?.patientShortId, el?.patient.firstName)}>
                                            <ReactSVG src={downloadIcon} />
                                            {t("parent_report")}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="2">{t("no_completed_tests_to_display")}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </section>
    );
}

export default PendingSessionTable;