import React, { useState, useEffect, useRef } from 'react';
import './regular_page.css';
import SubPage from './subpage.js';
import ChildPage from './ChildPage.js';
import KnowledgeBaseIntro from '../../components/knowledgebase/knowledge_base_intro.js';
import LoadingRing from '../../components/LoadingRing/index.js';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import ParentInstructions from './ParentInstructions.js';
import MedicalInstructions from './MedicalInstructions.js';

export default function Support({ country, showKnowledgeBaseIntro }) {

  const { auth, setAuth } = useAuth();
  //console.log(auth);
  //console.log(auth?.forceAnonymousPatients);


  const returnByParamaters = () => {

    if(auth.userType === 'MedicalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'MedicalUser') {
      if(auth?.forceAnonymousPatients === true || JSON.parse(localStorage.getItem('auth')).forceAnonymousPatients === true) {
        return <ParentInstructions></ParentInstructions>
      } else {
        return <MedicalInstructions></MedicalInstructions>
      }

    } else if (auth.userType === 'PersonalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'PersonalUser') {
      return <ParentInstructions></ParentInstructions>
    } 

  };

  return (
    <>
    {returnByParamaters()}
    </>
  );
}