import React from 'react';

const Login = ({
  t,
  userRef,
  pwdRef,
  setUser,
  setPwd,
  preLogin,
  togglePassword,
  passwordType,
  viewFirst,
  forgotPasswordView,
  user,
  pwd,
}) => {
  return (
    <div className={"peili-login-form peili-content-wrapper login-wrapper collapse " + viewFirst.current}>
      <div className='headings'>
        <h1>{t('login_header')}</h1>
      </div>

      <form autoComplete="off">
        <div className='item'>
          <label htmlFor="username">{t('login_user')}</label>
          <input
            type="text"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
          />
        </div>
        <div className='item'>
          <label htmlFor="password">{t('login_password')}</label>
          <input
            type={passwordType}
            id="password"
            ref={pwdRef}
            autoComplete="off"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
          />
          <div className="show-password" onClick={togglePassword}>
            <span className={passwordType === 'password' ? 'icon' : 'icon active'}></span>
          </div>
        </div>
        <button className='button mobile-adj' onClick={preLogin}>{t('login_btn')}</button>
      </form>

      <button className='mt-5 word-button primary-blue' onClick={forgotPasswordView}>
        {t("login_forgot")}?
      </button>
    </div>
  );
};

export default Login;