import React from 'react';

const Verification = ({
  t,
  handleSubmit,
  handleBack,
  preLogin,
  mfaType,
  codeInputFields,
  errMsg,
  errRef,
  viewSecondary,
  fieldsDisabled
}) => {
  return (
    <div className={"peili-content-wrapper authenticate-wrapper collapse " + viewSecondary.current}>
      <div className="authenticate-body">
        <div className='btn-holder'>
          <button className='button btn-orange' onClick={handleBack}>{t("back_btn")}</button> 
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h2>{t("2fa_header")}</h2>
          <div className='authentication-note'>
            <p>{t("2fa_string_1")} {t(mfaType)}. {t("2fa_string_2")}.</p>
          </div>
          <div className={'inputs-holder ' + fieldsDisabled}>{codeInputFields}</div>
          <button className='button mobile-adj'>{t('login_btn')}</button>
          <div className='resend-options'>
            <p>
              {t("2fa_string_3")}.<br />
              {t("2fa_string_4")}? <button type="button" onClick={preLogin}>{t("2fa_string_5")}.</button> 
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Verification;
