import { useState, useEffect, useRef } from 'react';
import PatientTable from '../../../components/tables/PatientTable';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import TestTable from '../../../components/tables/TestTable';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { ReactSVG } from "react-svg";
import { closeIcon } from '../../../icons';
import { useNavigate, useLocation } from 'react-router-dom';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

const OnboardingPopup = () => {
    const { auth } = useAuth();
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();

    const popupRef = useRef();

    const viewFirst = useRef('active');
    const viewSecondary = useRef('');
    const viewThird = useRef('');
    const viewFourth = useRef('');
    
    const [stepState, setStepState] = useState(34);
    const [nextBtnState, setNextBtnState] = useState(t('onboarding_btn_next'));
    const [previousBtnState, setPreviousBtnState] = useState('disabled');
    const [popupState, setPopupState] = useState('active');

    const [init, setInit] = useState(false);

    useOnClickOutside(popupRef, () => setPopupState(''));

    useEffect(() => {
        if(auth.welcomeOnboardingShownDate === "0001-01-01T00:00:00"){
            putOnboardingShown();
        }
        //console.log(auth);
        //console.log(auth.welcomeOnboardingShownDate);
    },[auth]);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const putOnboardingShown = async () => {
        let onBoardingDate = new Date();
        try {
            const response = await axiosPrivate.put('/Users/' + auth.id + '/WelcomeOnboardingShownDate', 
                onBoardingDate.toISOString() // Send the date string directly in the request body
            );
    
            //console.log('Response:', response.data);
            //console.log(auth);
        } catch (err) {
            console.error('Error putting date:', err);
        }
    };
  
  
    const handleSupportClick = (event) => {
      event.preventDefault(); // Prevent the default navigation behavior
      navigate('/support', { replace: true });
    };

    const handleNextStep = () => {

        //console.log(stepState);

        if(stepState <= 100) {
            setStepState(stepState + 34);
        } else if(stepState === 68) {
            setStepState(100);
            setNextBtnState(t('onboarding_btn_finish'));
        }

        if(viewFirst.current === 'active') {
            viewFirst.current = '';
            viewSecondary.current = 'active';
            viewThird.current = '';
            viewFourth.current = '';
            setPreviousBtnState('');
            setNextBtnState(t('onboarding_btn_next'));
        } else if(viewSecondary.current ==='active') {
            viewFirst.current = '';
            viewSecondary.current = '';
            viewThird.current = 'active';
            viewFourth.current = '';
            setNextBtnState(t('onboarding_btn_finish'));
        }

        if(nextBtnState === t('onboarding_btn_finish')) {
            setPopupState('');
        }

    }

    const handlePreviousStep = () => {
        //console.log(stepState);
        if(stepState > 34) {
            setStepState(stepState - 34);
            setNextBtnState(t('onboarding_btn_next'));
        }

        if(viewThird.current === 'active') {
            viewFirst.current = '';
            viewSecondary.current = 'active';
            viewThird.current = '';
            viewFourth.current = '';
        } else if(viewSecondary.current === 'active') {
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
            viewFourth.current = '';
            setPreviousBtnState('disabled');
        } else if(viewFourth.current === 'active') {
            viewFirst.current = '';
            viewSecondary.current = '';
            viewThird.current = 'active';
            viewFourth.current = '';
        }
    }

    
    return ( 
        <>
                <div ref={popupRef} className={"views-popup row border rounded-4 p-4 " + popupState }>
                    <div className="closer" onClick={() => setPopupState('')}><ReactSVG src={closeIcon} /><i></i></div>
                    <div className="onboarding-nav mt-4 mb-4">
                        <div className="steps">
                            <ProgressBar now={stepState} color={"#FFFFF"} />
                        </div>
                    </div>

                    <div className="views-wrapper">
                    
                        <section className={'peili-content-wrapper view-1 ' + viewFirst.current}>
                            <div className='column col-md-8 mb-4'>
                                <h2>{t("onboarding_heading_1")}</h2>
                                <p>{t("onboarding_string_0")}</p>
                                <p><br></br>{t("onboarding_string_1")}</p>
                            </div>
                        </section>

                        <section className={'peili-content-wrapper view-2 ' + viewSecondary.current}>
                            <div className='column col-md-8 mb-4'>
                                <h2>{t("onboarding_heading_2")}:</h2>

                                <p>{t("onboarding_string_2")}</p>
                                
                                <p><a href="" onClick={handleSupportClick}>{t("onboarding_link")}.</a> {t("onboarding_string_3")}:</p>
                            
                                <p><br></br><b>{t("onboarding_subheading_1")}:</b></p>

                                <p><br></br>- {t("onboarding_string_3")}</p>
                                <p>- {t("onboarding_string_4")}</p>
                                <p>- {t("onboarding_string_5")}</p>

                                <p><br></br><b>{t("onboarding_subheading_2")}:</b></p>

                                <p><br></br>- {t("onboarding_string_6")}</p>
                                <p>- {t("onboarding_string_7")}</p>
                                <p>- {t("onboarding_string_8")}</p>

                                <p>- {t("onboarding_string_9")}</p>
                                <p>- {t("onboarding_string_10")}</p>

                            </div>
                        </section>

                        <section className={'peili-content-wrapper view-3 ' + viewThird.current}>
                            <div className='column col-md-8 mb-4'>
                                <h2>{t("onboarding_heading_3")}:</h2>
                                
                                <p>- {t("onboarding_string_11")}</p>
                                <p>- {t("onboarding_string_12")}</p>

                                <p><br></br><b>{t("onboarding_subheading_3")}:</b></p>
                                <p>- {t("onboarding_string_13")}</p>
                                <p>- {t("onboarding_string_14")}</p>

                                <h2><br></br>{t("onboarding_subheading_4")}</h2>
                                <p>{t("onboarding_string_15")} <a href="mailto:support@efsimtest.com">support@efsimtest.com</a></p>
                            </div>
                        </section>

                    </div>

                    <div className="button-wrapper">
                        <button className={"btn btn-primary " + previousBtnState} onClick={handlePreviousStep}>{t('onboarding_btn_previous')}</button>
                        <button className="btn btn-primary" onClick={handleNextStep}>{nextBtnState}</button>
                    </div>

                </div>

        </>
    ) 
}

export default OnboardingPopup;