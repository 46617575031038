import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import * as XLSX from "xlsx";

import convertExcelToJson from "./components/util/excelToJson";
import translationXls from './locales/efsim_portal_translations.xls';

import translationFI from './locales/fi/translation.json';
import translationEN from './locales/en/translation.json';
import translationSV from './locales/sv/translation.json';

const test = async () => {
  try {
    const jsonData = await convertExcelToJson(translationXls);
  } catch (error) {
  }
};


const excelTranslations = await convertExcelToJson(translationXls);


/*const resources = { 
  en: {
    translation: { ...excelTranslations[0].en?.translation }
  },
  fi: {
    translation: { ...excelTranslations[0].fi?.translation }
  },
  sv: {
    translation: {} // Assuming no translations from Excel for Swedish
  }
};

console.log(resources);*/

const resources = { 
  en: {
    translation: {}
  },
  fi: {
    translation: {}
  },
  sv: {
    translation: {}
  }
};

excelTranslations.forEach(row => {
  const key = row.key;
  const en = row.en;
  const fi = row.fi;
  const sv = row.sv;

  if (key && en) {
    resources.en.translation[key] = en;
  }
  if (key && fi) {
    resources.fi.translation[key] = fi;
  }
  if (key && sv) {
    resources.sv.translation[key] = sv;
  }
});

const fallbackLng = ['en'];
const availableLanguages = ['en', 'fi', 'sv'];
const selectedLang = localStorage.getItem('i18nextLng');


const options = {
  // order and from where user language should be detected
  order: [ 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng', 
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  //caches: ['localStorage', 'cookie'],
  //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  //cookieMinutes: 10,
  //cookieDomain: 'https://peiliportaldev.azurewebsites.net',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false, 
    whitelist: availableLanguages,
    detection: options,
 
    interpolation: {
      escapeValue: false
    },
    resources,
    lng: selectedLang,
    // /*backend: { 
    //     loadPath: '/locales/{{lng}}/translation.json',//'/locales/{{lng}}/translation.json', // Public URL path
    // },*/
  });

export default i18n;